import React, { useEffect, useState } from 'react';
import './VideoCarousel.css'; // Update the CSS file name

const video = {
  src: 'https://storage.googleapis.com/marigold_videos/BRANDS%20-%20Izod%20Clothing%20Promo.mp4',
};

const VideoFeature = () => {
  const [videoHeight, setVideoHeight] = useState('56.25vw'); // Default for 16:9 videos

  useEffect(() => {
    const adjustVideoHeight = () => {
      const aspectRatio = 9 / 16;
      const videoWidth = document.querySelector('.video-wrapper2').offsetWidth;
      const newHeight = videoWidth * aspectRatio;
      setVideoHeight(`${newHeight}px`);
    };

    window.addEventListener('resize', adjustVideoHeight);
    adjustVideoHeight();

    return () => window.removeEventListener('resize', adjustVideoHeight);
  }, []);

  return (
    <div className="video-featured-container" style={{ height: videoHeight }}>
      <div className="video-wrapper2">
        <video
          src={video.src}
          autoPlay
          playsInline
          muted
          loop
          controls={false}
          style={{ width: '100%', height: '100vh' }}
          className="video-featured"
        ></video>
        <div className="overlay">
          <div className="text-overlay">
            <div className="tagline-large">
              <span className="visual-stories">CAPTURING</span>
              <span className="stories-wrapper"><span className="stories">FASHION,</span></span>
              <span className="speak-volumes">DRIVING RESULTS</span>
            </div>
            <div className="tagline-small">
              <span>Elevating your clothing and</span>
              <span>footwear brand with stunning visuals</span>
              <span>and strategic social media mastery.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoFeature;
