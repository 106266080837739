import React from 'react';
import './Offers.css';

const offers = [
    {
        tier: "CONTENT DAY",
        price: "$5,000",
        deliverables: [
            "Strategy Call",
            "15 Video Ideas",
            "Shoot 15 Videos in 1 Day",
            "Edit 15 Videos",
            "60 Clothing Photos"
        ]
    },
    {
        tier: "GROWTH PLAN",
        price: "$2,500",
        deliverables: [
            "Onboarding Call",
            "Monthly Strategy Call + Instagram Deal Getting Training",
            "8 Video Ideas Per Month",
            "Shoot 8 Videos Per Month",
            "Edit 8 Videos Per Month",
            "60 Clothing Photos Per Quarter",
            "1x Per Week on ALL Platforms",
            "Digital Door Knocking",
            "Scripts for Instagram DM Closing"
        ]
    },
    {
        tier: "AUTHORITY PLAN",
        price: "$5,500",
        deliverables: [
            "Onboarding Call",
            "Monthly Strategy Call + Instagram Deal Getting Training",
            "8 Video Ideas Per Month",
            "Shoot 8 Videos Per Month",
            "Edit 8 Videos Per Month",
            "60 Clothing Photos Per Quarter",
            "1x Per Week on ALL Platforms",
            "Digital Door Knocking",
            "Scripts for Instagram DM Closing",
            "Monthly Full Brand Video (3-5 min)",
            "1 Carousel Ad",
            "2 Follower Ads",
            "5 Retargeting Ads",
            "Ad Setup and Management"
        ]
    }
];

const Offers = () => {
    return (
        <div className="offers-container">
            <div className="offer-cards">
                {offers.map((offer, index) => (
                    <div className="offer-card" key={index}>
                        <h2 className="offer-tier">{offer.tier}</h2>
                        <table className="offer-table">
                            <thead>
                                <tr>
                                    <th>Deliverables</th>
                                </tr>
                            </thead>
                            <tbody>
                                {offer.deliverables.map((item, i) => (
                                    <tr key={i}>
                                        <td>{item}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="price-tag" key={index}>
                            <h3>{offer.price}</h3>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Offers;
