import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Page.css'; // Your CSS file for styling

const videos = [
  {
    src: 'https://storage.googleapis.com/marigold_videos/BRANDS%20-%20Coca%20Cola%20Spec%20Ad%20(1).mp4',
    header: 'Coca-Cola Commercial'
  },
  {
    src: 'https://storage.googleapis.com/marigold_videos/BRANDS%20-%20Trifecta%20Watches%20-%20Basketball%20Promo.mp4',
    header: 'Trifecta Watches - Basketball Promo'
  },
  {
    src: 'https://storage.googleapis.com/marigold_videos/HEYDUDE%20x%20Boopie%20Campaign.mp4',
    header: 'HEYDUDE x Boopie Campaign'
  },
  {
    src: 'https://storage.googleapis.com/marigold_videos/BRANDS%20-%20Izod%20Clothing%20Promo.mp4',
    header: 'Izod Clothing Promo'
  },
  {
    src: 'https://storage.googleapis.com/marigold_videos/Hard%20Jewlery%20Spec%20Ad.mp4',
    header: 'Hard Jewelry Spec Ad'
  },
  {
    src: 'https://storage.googleapis.com/marigold_videos/BRANDS%20-%20Insomnia%20Cookies%20Challenge%20Announcement.mp4',
    header: 'Insomnia Cookies Challenge'
  },
  {
    src: 'https://storage.googleapis.com/marigold_videos/BRANDS%20-%20Personal%20Athlete%20Promo%20for%20Urban%20Outfitters.mp4',
    header: 'Urban Outfitters Promo'
  },
];

const BrandVideos = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="brandsPabloPage">
      <div className='logo-container'>
        <Link to="/">
          <img src="https://i.ibb.co/XD4kJmq/Untitled-design-64.png" alt="Back" className="logo2" />
        </Link>
      </div>
      <div className="video-portfolio">
        {videos.map((video, index) => (
          <div key={index} className="video-item">
            <video
              src={video.src}
              loop
              controls
              style={{ width: '100%', height: 'auto' }}
            ></video>
            <div className="title">
              <span>{video.header}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandVideos;
