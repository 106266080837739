// In AboutUs.js
import React from 'react';
import './AboutUs.css';

const AboutUs = () => (
  <div className="about-us" id="aboutus">
    <div className="container">
      <div id="title-row">
        <h3><span id='true'>TRUE</span></h3>
        <div id="text-carousel" className="text-carousel">
          <div>Creative Partners</div>
          <div>Visual Storytellers</div>
          <div>Narrative Crafters</div>
        </div>
      </div>
      <p>
        Our service uniquely combines personalized brand development, strategic content creation, and proactive social media management, specifically tailored for high-quality clothing and footwear companies, to transform their online presence into a powerful tool for enhanced brand visibility and accelerated lead generation.
      </p>
    </div>
  </div>
);

export default AboutUs;
