import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Page.css'; // Your CSS file for styling

const videos = [
  {
    id: 1,
    src: 'https://storage.googleapis.com/marigold_videos/natalie.mp4',
    header: 'Natalie and Dylan Wedding Film'
  },
  {
    id: 2,
    src: 'https://storage.googleapis.com/marigold_videos/WEDDINGS%20-%20Trailer%20for%20full%20wedding%20video.mp4',
    header: 'Trailer for full wedding video'
  },
];

const WeddingVideos = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    window.scrollTo(0, 0); // Ensure the page scrolls to the top on load
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="brandsPabloPage">
      <div className='logo-container'>
        <Link to="/">
          <img src="https://i.ibb.co/XD4kJmq/Untitled-design-64.png" alt="Back" className="logo2" />
        </Link>
      </div>
      <div className="video-portfolio-picture">
        {videos.map((video, index) => (
          <div key={index} className="video-item">
            <video
              src={video.src}
              loop
              controls
              style={{ width: '100%', height: 'auto' }}
            ></video>
            <div className="title">
              <span>{video.header}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeddingVideos;
